import get from 'lodash/get'
import find from 'lodash/find'
import omit from 'lodash/omit'
import forEach from 'lodash/forEach'
import moment from 'moment'
import { reactive, ref } from '@vue/composition-api'
import { createFolder } from '@/helper/damUtils'

import { v4 as uuidv4 } from 'uuid'

export default (ctx) => {
  const {
    root: { $store },
  } = ctx

  const initialData = reactive({
    id: null,
    lang: null, // TODO: Recheck if this line will create a bug.
    title: '',
    project_id: null,
    description: '',
    folder_name: null,
    start_date: null,
    end_date: null,
    // gallery: [],
    thumbnail: null,
    external_link: '',
    is_approved: false,
    created_at: null,
    created_by: null,
    updated_by: null,
    updated_at: null
  })
  
  const btnSubmitLabel = ref('Submit')

  const formData = reactive({
    id: null,
    lang: null, // TODO: Recheck if this line will create a bug.
    title: '',
    project_id: null,
    description: '',
    folder_name: null,
    start_date: null,
    end_date: null,
    // gallery: [],
    thumbnail: null,
    external_link: '',
    is_approved: false,
    created_at: null,
    created_by: null,
    updated_by: null,
    updated_at: null
  })

  const projects = reactive({
    data: [],
  })

  const allProjects = reactive({
    data: []
  })

  const prepareFormData = (result) => {
    const data = omit(result, ['__typename'])
    forEach(formData, (value, key) => {
      formData[key] = data[key]
      initialData[key] = data[key]
    })

    const selectedProject = projects.data.find((project) => {
      return project.id === formData.project_id
    })

    if (!selectedProject) formData.project_id = null
    initialData.revision_id = data.revision_id
  }

  const fetchByRouteId = async () => {
    const id = get(ctx.root.$router.currentRoute.params, 'id', null)
    const folders = ['Image']
    if (id) {
      btnSubmitLabel.value = "Update"
      formData.id = id
      $store
        .dispatch('promotion/fetchOne', id)
        .then((result) => {
          prepareFormData(result)
          if (!formData.folder_name) {
            const randomUuid = uuidv4()
            formData.folder_name = randomUuid
          }
          return createFolder(`promotion/${formData.folder_name}`, folders)
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      const randomUuid = uuidv4()
      formData.folder_name = randomUuid
      return await createFolder(`promotion/${randomUuid}`, folders)
    }
  }

  const fetchProjectList = () => {
    const activeUser = ctx.root.$store.state.AppActiveUser
    return $store
      .dispatch('project/fetchProjectName')
      .then((items) => {
        allProjects.data = items

        const projectByGroupName = items.filter((project) => {
          const projectGroupName = get(project, ['user_group', 'group_name'], [])
          return (activeUser.groupName.includes(projectGroupName) || ctx.root.$acl.check('editor'))
        })

        projects.data = projectByGroupName
        return projectByGroupName
      })
  }

  const fetchProjectGalleryList = () => {
    const activeUser = ctx.root.$store.state.AppActiveUser
    return $store
      .dispatch('project/fetchProjectGallery')
      .then((items) => {
        allProjects.data = items

        const projectByGroupName = items.filter((project) => {
          const projectGroupName = get(project, ['user_group', 'group_name'], [])
          return (activeUser.groupName.includes(projectGroupName) || ctx.root.$acl.check('editor'))
        })

        projects.data = projectByGroupName
        return projectByGroupName
      })
  }

  const isDateRangeValid = () => {
    const promoList = $store.state.promotion.items
    const isDateRangeValid = find(promoList, (promo) => {

      if (promo.project_id === formData.project_id && promo.id !== formData.id) {
        // const toCheckStartDate = new Date(promo.start_date)
        // const toCheckEndDate = new Date(promo.end_date)
        // const startDate = new Date(formData.start_date)
        // const endDate = new Date(formData.end_date)

        return (
          (moment(formData.start_date).isSameOrBefore(promo.start_date, 'day') && moment(formData.end_date).isSameOrAfter(promo.end_date, 'day')) ||
          (moment(formData.start_date).isSameOrAfter(promo.start_date, 'day') && moment(formData.end_date).isSameOrBefore(promo.end_date, 'day')) ||
          (moment(formData.start_date).isBetween(promo.start_date, promo.end_date, 'day', '[]')) ||
          (moment(formData.end_date).isBetween(promo.start_date, promo.end_date, 'day', '[]'))
        )
        // return (
        //   (startDate <= toCheckStartDate && endDate >= toCheckEndDate) ||
        //   (startDate >= toCheckStartDate && endDate <= toCheckEndDate) ||
        //   (endDate <= toCheckEndDate && endDate >= toCheckStartDate) ||
        //   (startDate >= toCheckStartDate  && startDate <= toCheckEndDate)
        //   // (toCheckStartDate <= startDate && startDate <= toCheckEndDate) ||
        //   // (toCheckStartDate <= endDate && endDate <= toCheckEndDate) 
        // )
      }

      return false
    })

    return !isDateRangeValid
  }

  const getExistLang = async (id) => {
    const promotion = await $store.dispatch('promotion/fetchOne', id)
    const projectId = promotion ? promotion.project_id : null

    const existLang = await $store.dispatch('promotion/fetchExistProjectLang', projectId)

    return existLang
  }

  return {
    initialData,
    formData,
    projects,
    prepareFormData,
    fetchByRouteId,
    fetchProjectGalleryList,
    fetchProjectList,
    btnSubmitLabel,
    isDateRangeValid,
    getExistLang,
    allProjects
  }
}
