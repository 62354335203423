<template>
  <div>
    <PopupLanguage
      v-model="isPopupDuplicateActive"
      :on-click-btn="handlerDuplicateContent"
      name="property-asset-project-create"
      :hidden-lang="hiddenLang"
    />
    <div 
      id="data-list-list-view" 
      class="data-list-container">
      <custom-table
        ref="table"
        :searchKeys="searchKeys"
        :max-items="itemsPerPage"
        :data="items"
        pagination
        search
      >
        <div 
          slot="header" 
          class="flex items-center flex-grow justify-between">
          <AddNewContent
            :disabled="!isSale"
            name="property-promotion-create"
          />
          <!-- ITEMS PER PAGE -->
          <LhDropdownPerPage 
            :items="items" 
            :items-per-page="itemsPerPage" 
            :current-page="currentPage" 
            :queried-items="queriedItems"
            @click="(page) => itemsPerPage = page"
          />
        </div>

        <template slot="thead">
          <vs-th sort-key="title">โปรโมชัน</vs-th>
          <vs-th sort-key="project_title">โครงการ</vs-th>
          <vs-th sort-key="start_date">วันที่เริ่ม - สิ้นสุด</vs-th>
          <vs-th sort-key="is_approved">สถานะ</vs-th>
          <vs-th sort-key="updated_at">วันที่แก้ไขล่าสุด</vs-th>
          <vs-th>Action</vs-th>
        </template>

        <template slot-scope="{ data }">
          <tbody>
            <vs-tr 
              v-for="(tr, indextr) in data" 
              :data="tr" 
              :key="indextr">
              <td class="td vs-table--td" @click.stop="editData(tr.id)">{{ tr.title }}</td>
              <td class="td vs-table--td" @click.stop="editData(tr.id)">{{ tr.project_title }}</td>
              <td class="td vs-table--td" @click.stop="editData(tr.id)"
              >{{ publicDateFormat(tr.start_date) }} -
                {{ publicDateFormat(tr.end_date) }}</td>
              <td class="td vs-table--td" @click.stop="editData(tr.id)">
                <vs-chip
                  v-if="tr.is_approved"
                  :color="'success'"
                  class="product-order-status"
                >อนุมัติ</vs-chip
                >
                <vs-chip
                  v-if="!tr.is_approved"
                  :color="'warning'"
                  class="product-order-status"
                >รออนุมัติ</vs-chip
                >
              </td>
              <td class="td vs-table--td" @click.stop="editData(tr.id)">
              <p class="brand-priority">
                {{ updatedAtDatetimeFormat(tr.updated_at) }}
              </p></td>
              <vs-td class="whitespace-no-wrap cursor-default">
                <div class="flex space-x-2">
                  <feather-icon
                    class="cursor-pointer"
                    v-if="isSale"
                    icon="CopyIcon"
                    svg-classes="w-5 h-5 hover:text-primary stroke-current"
                    @click.stop="duplicateContent(tr.id)"
                  />
                  <router-link
                    :to="{ name: `${routePrefix}-edit`, params: { id: tr.id }}"
                    class="pt-2 color-text-link"
                  >
                    <feather-icon
                      :icon="isEditor || !tr.is_approved ? 'EditIcon' : 'SearchIcon'"
                      svg-classes="w-5 h-5 hover:text-primary stroke-current"
                    />
                  </router-link>
                  <feather-icon
                    class="cursor-pointer"
                    v-if="isEditor || !tr.is_approved"
                    icon="TrashIcon"
                    svg-classes="w-5 h-5 hover:text-danger stroke-current"
                    @click.stop="deleteData(tr.id)"
                  />
                </div>
              </vs-td>
            </vs-tr>
          </tbody>
        </template>
      </custom-table>
    </div>
  </div>
</template>

<script>
import { computed, onMounted, watch, ref } from '@vue/composition-api'
import map from 'lodash/map'
import find from 'lodash/find'
import get from 'lodash/get'
import useCrud from '@/use/useCrud'
import useDatepicker from '@/use/useDatepicker'
import AddNewContent from '@/components/AddNewContent'
import PopupLanguage from '@/components/PopupLanguage'
import usePromotionUtil from '@/use/usePromotionUtil'
import LhDropdownPerPage from '@/components/LhDropdownPerPage'
import CustomTable from '@/components/CustomTable'

export default {
  name: 'Index',
  components: { PopupLanguage, AddNewContent, LhDropdownPerPage, CustomTable },
  setup(props, ctx) {
    const crudFunction = useCrud(ctx, 'promotion', {
      fetchOnMount: false,
      onModifyItemSuccess: props.onCreateSuccess,
    })
    const { moment, updatedAtDatetimeFormat } = useDatepicker()
    const { formData, prepareFormData, projects, fetchProjectList, getExistLang, allProjects } = usePromotionUtil(
      ctx,
    )
    const loading = ref(true)

    crudFunction.routePrefix.value = 'property-promotion'

    const hiddenLang = ref([ctx.root.$store.state.locale.currentLang])

    onMounted(async () => {
      await ctx.root.$vs.loading()
      loading.value = true
      await fetchProjectList()
      await ctx.root.$store.dispatch('promotion/fetch')
      await ctx.root.$vs.loading.close()
      loading.value = false
    })

    const items = computed(() => {
      if (loading.value) return []
      const activeUser = ctx.root.$store.state.AppActiveUser

      const promotionList = map(crudFunction.result.value, (item) => {
        const matchedProject = find(allProjects.data, ['id', item.project_id])
        const project_title = matchedProject ? matchedProject.title : '-'

        return {
          ...item,
          project_title,
          project_id: matchedProject ? item.project_id : null,
          group_name: get(matchedProject, ['user_group', 'group_name'], null)
        }
      })

      const filterPromotion = promotionList.filter((promotion) => activeUser.groupName.includes(promotion.group_name) || ctx.root.$acl.check('editor') || (promotion.created_by === activeUser.email && !promotion.project_id))
      return filterPromotion
    })

    const handlerDuplicateContentFromCRUD = async (
      formData,
      prepareFormData,
      langToDuplicationContent,
    ) => {
      ctx.root.$vs.loading()
      const activeUser = ctx.root.$store.state.AppActiveUser
      await ctx.root.$store.dispatch('locale/changeLang', langToDuplicationContent)
      const result = await ctx.root.$store.dispatch(
        'promotion/fetchOne',
        crudFunction.contentDuplicateId.value,
      )
      await prepareFormData(result)
      const resultDuplicate = await ctx.root.$store.dispatch('promotion/addItem', {
        ...formData,
        created_by: activeUser.email,
        updated_by: activeUser.email,
        is_approved: false,
        id: null,
      })
      
      crudFunction.isPopupDuplicateActive.value = false
      await ctx.root.$vs.loading.close()
      await ctx.root.$router.push({
        name: `${crudFunction.routePrefix.value}-edit`,
        params: {
          id: resultDuplicate.id,
        },
      })
    }

    const handlerDuplicateContent = (langToDuplicationContent) => {
      handlerDuplicateContentFromCRUD(formData, prepareFormData, langToDuplicationContent)
    }

    const duplicateContent = async (id) => {
      hiddenLang.value = await getExistLang(id)
      return crudFunction.duplicateContent(id)
    }

    const publicDateFormat = (value) => {
      return moment(value).format('DD MMM YYYY')
    }

    watch(
      () => ctx.root.$store.state.locale.currentLang,
      (newValue, oldValue) => {
        if (oldValue && newValue) fetchProjectList()
      },
    )

    const isEditor = computed(() => {
      return ctx.root.$acl.check('editor')
    })

    const isSale = computed(() => {
      return ctx.root.$acl.check('sale_not_editor')
    })

    const isSuperEditor = computed(() => {
      return ctx.root.$acl.check('super_editor')
    })

    const queriedItems = computed(() => {
      return ctx.refs.table ? ctx.refs.table.queriedResults.length : items.value.length
    })

    const editData = (id) => {
      // this.sidebarData = JSON.parse(JSON.stringify(this.blankData))
      ctx.root.$router.push({
        name: `${crudFunction.routePrefix.value}-edit`,
        params: { id },
      })
    }

    return {
      ...crudFunction,
      queriedItems,
      items,
      projects,
      handlerDuplicateContent,
      publicDateFormat,
      updatedAtDatetimeFormat,
      hiddenLang,
      duplicateContent,
      isEditor,
      isSale,
      isSuperEditor,
      editData,
      searchKeys: ['title', 'project_title', 'start_date', 'end_date'],
    }
  },
}
</script>

<style scoped></style>
